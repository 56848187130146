import React, { useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import cookies from 'next-cookies';
import { END } from 'redux-saga';
import App from 'next/app';
import 'bootstrap/dist/css/bootstrap.css';
import { get_my_data_saga } from "../store/actions/my-info";
import { change_language } from "../store/actions/my-info";
import { settings_data } from "../store/actions/my-info";
import getPosition from "../utils/getDistance";
import authApi from "../services/authinstance";
import keys from "../services/keys";
import { wrapper } from '../store';
import "../styles/globals.scss";
import NavigationLoader from '../components/NavigationLoader';

const canUseDOM = !!(
  (typeof window !== 'undefined' &&
    window.document && window.document.createElement)
);
const jwt = require("jsonwebtoken");
const contextClass = {
  success: "#BBFBC5",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};
class MyApp extends App {

  componentDidMount() {
    const language = this.props.language ? this.props.language : 'fr';
    // if (this.props.log_out) {
    //   window.location = `/${language}/start`;
    //   document.cookie = `token=""; path=/`;
    // }
    if (this.props.geolocaton && canUseDOM && !this.props.lat && !this.props.lng) {
      getPosition();
    };

  };

  static async getInitialProps({ Component, ctx }) {
    const currentLocale = ctx.locale;
    try {
      var geolocaton = false;
      let log_out = false;
      // 1. Wait for all page actions to dispatch
      const { token, user_id } = cookies(ctx);

      const pageProps = {
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
      };
      var meta_file = {};
      // 2. Stop the saga if on server

      if (ctx.req) {
        const locale = ctx.req.cookies.locale ? ctx.req.cookies.locale : 'fr';
        try {

        } catch (error) {
          log_out = true;
          return {
            log_out,
            pageProps
          };
        }
        // };
        // ctx.store.dispatch(get_menu())

        token ? ctx.store.dispatch(get_my_data_saga({ token })) : null;
        // const meta_file_res = await authApi.get('https://api.beautycils.app/media/static/images/2022/08/23/metags-file.json');
        // meta_file = meta_file_res.data;

        // if (locale && ctx.locale !== locale && !ctx.req.originalUrl.includes(locale)) {
        //   ctx.res.writeHead(308, { Location: `/${locale}${ctx.asPath}` }).end();
        // }
      };
      ctx.store.dispatch(END);
      await ctx.store.sagaTask.toPromise();
      // 3. Return props

      return {
        log_out,
        geolocaton,
        pageProps,
        token,
        language: ctx.locale,
        meta_file,
        lat: ctx.req.cookies.lat,
        lng: ctx.req.cookies.lng
      };
    } catch (error) {
    }
  };

  render() {
    const { Component, pageProps, _nextI18Next } = this.props;


    return (
      <>
        <main className="cover-container">
          <NavigationLoader />
          <ToastContainer
            toastClassName={contextClass}
            position="bottom-right"
            autoClose={5000} />
            <Component meta_file={this.props.meta_file} {...pageProps} />
        </main>
      </>
    );
  };
};

export default appWithTranslation(wrapper.withRedux(MyApp));