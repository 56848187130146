import React, { useState } from 'react';
import styles from "./LoadingLayer.module.scss";
import ClipLoader from "react-spinners/ClipLoader";
function LoadingLayer(props) {
    return (
        <div className={styles.layer}>
            <ClipLoader color="#464646" size={30} />
        </div>
    )
}
export default LoadingLayer;